import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, skip, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

import { environment } from 'src/environments/environment';
import { Register } from 'src/app/shared';
import { OccupancyApplication } from '../../models/users';
const credentialsKey = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  public isSaveAndExit = new BehaviorSubject({ isSaveAndExit: false, currentForm: null });

  public userDetails = new BehaviorSubject(null);
  public userDetailsObs = this.userDetails.asObservable();

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  registerUser(data: Register): Observable<any> {
    const href = `${environment.register}`;
    return this.http.post<any>(href, data).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
            this.authenticationService.setUser(data.response);
          }
          return data;
        }
      )
    );
  }

  updateUserProfile(payload): Observable<any> {
    const href = `${environment.updateProfile}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

  setCurrentApplication(application) {
    sessionStorage.setItem("application", JSON.stringify(application));
  }

  removeApplication() {
    sessionStorage.removeItem("application");
  }

  getCurrentApplication() {
    const data =  sessionStorage.getItem("application") ? (JSON.parse(sessionStorage.getItem("application")) || null) : null;
    // if(data && data.renewed_application_details){
    //   return data.renewed_application_details
    // }
    return data
  }

  checkSaveAndExit(): Observable<any> {
    return this.isSaveAndExit.asObservable().pipe(skip(1));
  }

  getApplicationList(pagination): Observable<any> {
    const href = environment.getApplicationList;
    return this.http.get<any>(href, {params: pagination}).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }

  getApplicationById(id): Observable<any> {
    const href = environment.getApplicationById;
    return this.http.get<any>(href, {params: id}).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }


  renewPermit(payload): Observable<any> {
    // const href = environment.renewPermit;
    // return this.http.get<any>(href, {params: id}).pipe(map((data: any) => {
    //   if (data.status == 'success') {
    //     return data.response;
    //   }
    // }));
    const href = `${environment.renewPermit}`;
    return this.http.post<any>(href, payload).pipe(
      tap(
        (data) => {
          if (data.status === 'success') {
          }
          return data;
        }
      )
    );
  }

 

  getCityList(): Observable<any> {
    const href = environment['getCityList'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  setSelectedCity(id) {
    sessionStorage.setItem('selectedCity', id);
  }

  getSelectedCity() {
    return sessionStorage.getItem('selectedCity')
  }

  removeSelectedCity() {
    sessionStorage.removeItem('selectedCity');
  }
  verifiyOtpProfile(data){
    const href = environment.verifiyOtpProfile;
    return this.http.post<any>(href, data).pipe(map((res: any) => {
      if (res.status === 'success') {
        const storage = localStorage;
        storage.setItem(credentialsKey, JSON.stringify(res.response));
      }
      if (res.status == 'success') {
        return res;
      }
    }));
  }
  sendProfileOtp(payload){
    const href = environment.sendProfileOtp;
    return this.http.get<any>(href, {params: payload}).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data;
      }
    }));
  }
}

import { LoaderService } from 'src/app/core/services';
import { Component, OnDestroy,NgZone, OnInit,Renderer2,Inject,HostListener} from '@angular/core';
import { NavigationStart, Router,NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import { Location,LocationStrategy } from '@angular/common';


import { AuthenticationService } from './core/authentication/authentication.service';
// import { ToasterConfig } from 'ngx-toastr';
export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  pageTitle: string = 'My Angular App';
  // public config: ToasterConfig = new ToasterConfig({
  //   animation: 'fade',
  //   positionClass: 'toast-top-full-width',
  //   showCloseButton: true,
  //   timeout: 8000
  // });

  public interval: any;
  isConditionMet = true;
  encryptedData:any;
  secretKey = 'mySecretKey123';
  private subscription: Subscription = new Subscription();
  decryptedData:any;
  isTrue:any;
  currentPath:any;
  constructor(private authenticationService: AuthenticationService,private location:Location,private zone: NgZone,private locations: LocationStrategy, private router: Router, private loaderService: LoaderService,private titleService: Title,private renderer: Renderer2,    @Inject(DOCUMENT) private document: Document
  ) {
    
    this.subscription.add(router.events.subscribe((event: any) => {
      var updatedURL = this.router.url.replace(/[?&]data=([^&#]*)/, '');
      // console.log("updatedURL",updatedURL)
      if (event instanceof NavigationEnd) {
        this.currentPath = this.router.url;
        if(updatedURL == '/auth/home'   ){
          this.isTrue = false
        }else{
          this.isTrue = true;
        }
        // browserRefresh = !router.navigated;
      }
      // if (event instanceof NavigationStart) {      
        browserRefresh = !router.navigated;
        // console.log("/auth/home",updatedURL)
        if(updatedURL == '/auth/home' || updatedURL == '/'){
          this.isConditionMet = true;
          this.setTitle(1);

        }else{
          this.isConditionMet = false;
          this.setTitle(2);

        }
      // }
    }));

  }

  ngAfterViewInit() {
    // console.log("data is ",)  
    // window.addEventListener('popstate', (event) => {
    //   // Replace the current state with the initial state
    //   console.log("value is ",event)
    //   window.history.replaceState(null, '', this.location.path());
    // });
  }

  setFavicon() {
    const linkElement = this.document.querySelector('link[rel="icon"]') || this.document.createElement('link');
    this.document.head.appendChild(linkElement);

    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', 'image/x-icon');
    linkElement.setAttribute('href', this.getFavicon());
  }

  getTitle(): string {
    // console.log("data is ",this.isConditionMet )
    return this.isConditionMet ?  'Alarm Permit' : 'License Portal' ;
  }
  

  getFavicon(): string {
    return this.isConditionMet ? 'assets/images/fav.png' : 'assets/images/fav.png';
  }

  setTitle(val) {
    if(val == 1){
      this.titleService.setTitle('License Portal');
      this.getTitle()
    }else{
      
      this.titleService.setTitle('Alarm Permit');
      this.getTitle()

    }

    this.setFavicon();
  }
  
  ngOnInit(): void {
    this.subscription.add(this.authenticationService.obsLoginDetails.subscribe((data: any) => {
      if (data) {
        // console.log("get csrf token")
        // this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
        //   sessionStorage.setItem('csrfToken', data);
        // }));
        // this.intervalFunction();
      } else {
        this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
          const currentApplicantUser = user ? user : null;
          if (currentApplicantUser && currentApplicantUser.isUser) {
            this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
              sessionStorage.setItem('csrfToken', data);
            }));
            // this.intervalFunction();
          }
        }));
      }
    }));
    this.subscription.add(this.authenticationService.obsLoggedOutBs.subscribe((data: any) => {
      if (data) {
        // console.log("get csrf token")
        clearInterval(this.interval);
      }
    }))
    this.loaderService.show();
    setTimeout(() => {
      this.loaderService.hide();
    }, 7000)
  }
  // updateTitleBasedOnCondition(): void {
  //   // Example: conditionally change the title
  //   // if (someCondition) {
  //     this.pageTitle = 'New Title';
  //   // }
  // }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  intervalFunction() {
    this.interval = setInterval(() => {
      this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
        sessionStorage.setItem('csrfToken', data);
        // console.log("csrf token value is ")
      }));
    }, 50000);
  }

}

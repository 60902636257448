import { ApplicationService } from 'src/app/core/http/users/application.service';
import { AuthenticationService } from './../../../authentication/authentication.service';
import { NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { appToaster } from 'src/app/configs';
import { UsersService } from 'src/app/core/services';
import { settingConfig } from 'src/app/configs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {
  public currentUser;
  public isApplicationEditable = false;
  public isFormActive = false;
  private subscription: Subscription = new Subscription();
  public activeRoute: any;
  public currentApplication = null;
  public showSaveAndExit = false;
  public settingsConfig = settingConfig;
  public payment_status:any;
  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    private toasterService: ToastrService,
    private userService: UsersService,
    private applicationService: ApplicationService
  ) {

    /** GET CURRENT ROUTE */

    this.subscription.add(this.router.events.subscribe((event: any) => {
      // console.log("first")
      if (event instanceof NavigationEnd) {
        this.currentApplication = this.userService.getCurrentApplication();
        console.log("data",this.currentApplication)
        this.activeRoute = event.url.split('/')
        if (this.activeRoute[3] && this.activeRoute[3].includes('what') && (!this.currentApplication)) {
          this.showSaveAndExit = false;
        } else if ((this.currentApplication && (this.currentApplication.response.id)) && (this.activeRoute[3] && (this.activeRoute[3].includes('what')))) {
          this.showSaveAndExit = true;
        } else if ((this.activeRoute[3] && (!this.activeRoute[3].includes('what')))) {
          this.showSaveAndExit = true;
        } else if ((this.activeRoute[3] && (this.activeRoute[3].includes('what'))) && this.currentApplication && (!this.currentApplication.response.id)) {
          this.showSaveAndExit = false;
        }
        if (this.activeRoute[2] == 'application' && (!this.activeRoute[this.activeRoute.length - 1].includes('payment'))) {
          this.isFormActive = true;
        } else {
          this.isFormActive = false;
        }
      }
      //  console.log("service data==>",this.userService.getCurrentApplication(),"second",this.currentApplication)
    //  if (this.currentApplication && this.currentApplication.response) {
    //       let temp = this.currentApplication.response.application_payment_data.sort(function (a, b) {
    //         let c:any= new Date(a.createdAt);
    //         let d:any= new Date(b.createdAt);
    //         return Number(c) - Number(d);
    //       });
    //       // console.log("temp val is",temp)
    //       temp.forEach((obj)=>{
    //       // console.log("obj is",obj.status)
    //       this.payment_status = obj.status
    //       })
    //     }
    }));

    
  }

  ngOnInit() {
    
    this.subscription.add(this.userService.userDetailsObs.subscribe((data: any) => {
      if (data) {
        this.currentUser = data ? data : null;
      } else {
        if (this)
          this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
            this.currentUser = user ? user : null;
          })
          );
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToLogin() {
    // environment.busineslicenseURL
    // 'http://localhost:4200/'
    window.location.href = environment.ap
  }

  onClickProfile() {
    this.router.navigateByUrl('user/update-profile');
  }

  onClickLogout() {
    this.authenticationService.logout();
    this.toasterService.success(appToaster.logoutSuccess);
    this.goToLogin();
  }

  onClickSaveAndExit() {
    let count = 0;
    let loopCount = 0;
    let whatFormPermit = this.applicationService.getWhatFormData() ? this.applicationService.getWhatFormData()['permit'] : '';
    let fireInstallerFormValid = this.applicationService.getFireInstallerForm();
    let burglarInstallerFormValid = this.applicationService.getBurglarInstallerForm();
    if (whatFormPermit) {
      if (this.currentApplication && whatFormPermit == 3 && this.currentApplication.response.application_installer_details && this.currentApplication.response.application_installer_details.length > 0 && (this.currentApplication.response.application_installer_details[0].installation_date == null || this.currentApplication.response.application_installer_details[1].installation_date == null)) {
        this.toasterService.error('Please fill both installer details');
      } else if (this.currentApplication && whatFormPermit == 1 && this.currentApplication.response.application_installer_details && this.currentApplication.response.application_installer_details.length > 0) {
        this.currentApplication.response.application_installer_details.forEach((installerObj) => {
          loopCount++;
          if (installerObj.installer_type == 1 && installerObj.installation_date == null) {
            this.toasterService.error('Please fill burglar installer details');
            count++;
          }
          if (loopCount == this.currentApplication.response.application_installer_details.length && count == 0) {
            if (this.activeRoute[this.activeRoute.length - 1] == 'review') {
              this.router.navigate(['user/index']);
            } else {
              this.applicationService.setSaveAndExit(true);
              this.userService.isSaveAndExit.next({ isSaveAndExit: true, currentForm: this.activeRoute[this.activeRoute.length - 1] });;
            }
          }
        });
      } else if (this.currentApplication && whatFormPermit == 2 && this.currentApplication.response.application_installer_details && this.currentApplication.response.application_installer_details.length > 0) {
        this.currentApplication.response.application_installer_details.forEach((installerObj) => {
          loopCount++;
          if (installerObj.installer_type == 2 && installerObj.installation_date == null) {
            this.toasterService.error('Please fill fire installer details');
            count++;
          }
          if (loopCount == this.currentApplication.response.application_installer_details.length && count == 0) {
            if (this.activeRoute[this.activeRoute.length - 1] == 'review') {
              this.router.navigate(['user/index']);
            } else {
              this.applicationService.setSaveAndExit(true);
              this.userService.isSaveAndExit.next({ isSaveAndExit: true, currentForm: this.activeRoute[this.activeRoute.length - 1] });;
            }
          }
        });
      } else {
        if (this.activeRoute[this.activeRoute.length - 1] == 'review') {
          this.router.navigate(['user/index']);
        } else {
          this.applicationService.setSaveAndExit(true);
          this.userService.isSaveAndExit.next({ isSaveAndExit: true, currentForm: this.activeRoute[this.activeRoute.length - 1] });;
        }
      }
    } else {
      if (this.currentApplication && this.currentApplication.response.type_of_permit == 3 && this.currentApplication.response.application_installer_details && this.currentApplication.response.application_installer_details.length > 0 && (this.currentApplication.response.application_installer_details[0].installation_date == null || this.currentApplication.response.application_installer_details[1].installation_date == null)) {
        if (fireInstallerFormValid === false || burglarInstallerFormValid === false) {
          this.toasterService.error('Please fill both installer details');
        } else if (this.activeRoute[this.activeRoute.length - 1] == 'review') {
          this.router.navigate(['user/index']);
        } else {
          this.applicationService.setSaveAndExit(true);
          this.userService.isSaveAndExit.next({ isSaveAndExit: true, currentForm: this.activeRoute[this.activeRoute.length - 1] });;
        }
      } else if (this.currentApplication && this.currentApplication.response.type_of_permit == 1 && this.currentApplication.response.application_installer_details && this.currentApplication.response.application_installer_details.length > 0) {
        this.currentApplication.response.application_installer_details.forEach((installerObj) => {
          loopCount++;
          if ((burglarInstallerFormValid === false) && installerObj.installer_type == 1 && installerObj.installation_date == null) {
            this.toasterService.error('Please fill burglar installer details');
            count++;
          }
          if (loopCount == this.currentApplication.response.application_installer_details.length && (count == 0)) {
            if (this.activeRoute[this.activeRoute.length - 1] == 'review') {
              this.router.navigate(['user/index']);
            } else {
              this.applicationService.setSaveAndExit(true);
              this.userService.isSaveAndExit.next({ isSaveAndExit: true, currentForm: this.activeRoute[this.activeRoute.length - 1] });;
            }
          }
        });
      } else if (this.currentApplication && this.currentApplication.response.type_of_permit == 2 && this.currentApplication.response.application_installer_details && this.currentApplication.response.application_installer_details.length > 0) {
        this.currentApplication.response.application_installer_details.forEach((installerObj) => {
          loopCount++;
          if ((fireInstallerFormValid === false) && installerObj.installer_type == 2 && installerObj.installation_date == null) {
            this.toasterService.error('Please fill fire installer details');
            count++;
          }
          if (loopCount == this.currentApplication.response.application_installer_details.length && (count == 0)) {
            if (this.activeRoute[this.activeRoute.length - 1] == 'review') {
              this.router.navigate(['user/index']);
            } else {
              this.applicationService.setSaveAndExit(true);
              this.userService.isSaveAndExit.next({ isSaveAndExit: true, currentForm: this.activeRoute[this.activeRoute.length - 1] });;
            }
          }
        });
      } else {
        if (this.activeRoute[this.activeRoute.length - 1] == 'review') {
          this.router.navigate(['user/index']);
        } else {
          this.applicationService.setSaveAndExit(true);
          this.userService.isSaveAndExit.next({ isSaveAndExit: true, currentForm: this.activeRoute[this.activeRoute.length - 1] });;
        }
      }
    }

  }

  navigateToIndexPage() {
    this.router.navigate(['user/index']);
  }

  onClickACHAccount() {
    this.router.navigate(['user/ach/index']);
  }

  navigateToPaymentPage() {
    let count = 0;
    let loopCount = 0;
    if (this.currentApplication && this.currentApplication.response.type_of_permit == 3 && this.currentApplication.response.application_installer_details.length > 0 && (this.currentApplication.response.application_installer_details[0].installation_date == null || this.currentApplication.response.application_installer_details[1].installation_date == null)) {
      this.toasterService.error('Please fill both installer details');
    } else if (this.currentApplication && this.currentApplication.response.type_of_permit == 1 && this.currentApplication.response.application_installer_details.length > 0) {
      this.currentApplication.response.application_installer_details.forEach((installerObj) => {
        loopCount++;
        if (installerObj.installer_type == 1 && installerObj.installation_date == null) {
          this.toasterService.error('Please fill burglar installer details');
          count++;
        }
        if (loopCount == this.currentApplication.response.application_installer_details.length && count == 0) {
          debugger;
          // console.log("1",this.checkFeeType(this.currentApplication.response))
          this.router.navigate(['user/application/payment'], { queryParams: { applicationId: this.currentApplication.response.id, feeType: this.checkFeeType(this.currentApplication.response) } });
        }
      });
    } else if (this.currentApplication && this.currentApplication.response.type_of_permit == 2 && this.currentApplication.response.application_installer_details.length > 0) {
      this.currentApplication.response.application_installer_details.forEach((installerObj) => {
        loopCount++;
        if (installerObj.installer_type == 2 && installerObj.installation_date == null) {
          this.toasterService.error('Please fill fire installer details');
          count++;
        }
        if (loopCount == this.currentApplication.response.application_installer_details.length && count == 0) {
                    // console.log("2")
          this.router.navigate(['user/application/payment'], { queryParams: { applicationId: this.currentApplication.response.id, feeType: this.checkFeeType(this.currentApplication.response) } });
        }
      });
    } else {
                // console.log("3")
      this.router.navigate(['user/application/payment'], { queryParams: { applicationId: this.currentApplication.response.id, feeType: this.checkFeeType(this.currentApplication.response) } });
    }
  }
  saveWithoutpayment(){
    const payload = {
      'application_id': this.currentApplication.response.id,
    }
    this.subscription.add(
      this.applicationService.saveWithoutpayment(payload).subscribe((data) => {
        if (this.activeRoute[this.activeRoute.length - 1] == 'review') {
          this.router.navigate(['user/index']);
        }
        // this.subscription.unsubscribe();
      })
    );

  }

  checkFeeType(application) {
    // console.log("fun call",application)
    // if(this.payment_status == null && application.application_payment_data.length > 0){
    //     console.log("for all",this.payment_status)
    //     return 1
    //  }else if( this.payment_status == 0 && application.application_payment_data.length > 0){
    //   return 0;
    //  }else {
    //   return 0;
    //  }
    if (application.application_payment_data && application.application_payment_data.length > 0) {
      console.log("inner fun call",application)
      application.application_payment_data.forEach((element) => {
        if (element.fee_type == 0 && element.status == 3) {
          console.log("if")
          return 1;
        } 
        else if (element.fee_type == 0 && (element.status == 0 || element.status == null)) {
           console.log("nested else if")
          return 0;
        }
        
      });
    } else {
      console.log("else if")
      return 0;
    }
  }
  url:any;
  switchToOtherPortal(val) {
    const encryptedData =  sessionStorage.getItem('encryptedData');
    const csrfToken = sessionStorage.getItem('csrfToken');
    // console.log("token",csrfToken)
    if (val == 1) {
      window.location.href = `${environment.cannabisUrl}user/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`;
    } else if (val == 2) {
      window.location.href  = `${environment.tradeWasteUrl}user/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`
    } else if (val == 4) {
      window.location.href = `${environment.busineslicenseURL}user/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`;
    }else if (val == 5) {
      // window.location.href = environment.dogUrl;
      window.open(environment.dogUrl)
    }
  }
  navigateToIndex(){
    window.location.reload();
  }

  getCSRFtoken(){
    this.subscription.add(this.authenticationService.getCSRFToken().subscribe((data: any) => {
        sessionStorage.setItem('csrfToken', data);
        console.log("csrf token value is ")
      }));
  }
  backToLogin(){
    const encryptedData =  sessionStorage.getItem('encryptedData');
    window.location.href = `${environment.ap}?data=${encryptedData}`;
  }


}

/** MODUELS */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/** END MODUELS */

/** COMPONENTS */
import { LayoutComponent } from './layouts/users/layout/layout.component';
import { HeaderComponent } from './layouts/users/header/header.component';
import { FooterComponent } from './layouts/users/footer/footer.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthHeaderComponent } from './layouts/auth-layout/auth-header/auth-header.component';
import { AdminHeaderComponent } from './layouts/admin/admin-header/admin-header.component';
import { AdminFooterComponent } from './layouts/admin/admin-footer/admin-footer.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout/admin-layout.component';
import { AdminSidebarComponent } from './layouts/admin/admin-sidebar/admin-sidebar.component';
/** End COMPONENTS */


const COMPONENTS = [
  AuthLayoutComponent,
  LayoutComponent,
  HeaderComponent,
  FooterComponent,
  AuthHeaderComponent,
  AdminHeaderComponent,
  AdminFooterComponent,
  AdminLayoutComponent,
  AdminSidebarComponent
];

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule
];



@NgModule({
  declarations: [
    ...COMPONENTS,

  ],
  imports: [
    ...BASE_MODULES
  ],
  exports: [
    ...BASE_MODULES,
    ...COMPONENTS,
  ],
  providers: [
    LayoutsModule
  ]
})
export class LayoutsModule {
  // static forRoot(): ModuleWithProviders {
  //   return <ModuleWithProviders>{
  //     ngModule: LayoutsModule,
  //   };
  // }
}

/**
 * API URLS
 */
export const baseUrl = 'https://ap.appening.xyz/'
export const urls = { 
  login: 'auth/api/login',
  register: 'auth/api/register',
  updateProfile: 'api/user/profile',
  sendPasswordLink: 'auth/api/forget-password/send-email',
  resetPassword: 'auth/api/forget/create-password',
  adminLogin: 'auth/api/admin/login',
  createStaffPassword: 'auth/api/staff/create-password',
  resetStaffPassword: 'auth/api/staff/reset-password',
  sendPasswordLinkStaff: 'auth/api/staff/create-password/send-email',
  sendResetPasswordLinkStaff: 'auth/api/staff/reset-password/send-email',
  sendProfileOtp:'api/user/send-profile-phone-otp',
  verifiyOtpProfile:'api/user/verify-profile-otp',
  emailLinkVerificationadmin:'auth/api/validate-admin-email-link',

  application: 'api/application',
  getApplicationList: 'api/applications',
  getApplicationById: 'api/application-by-id',

  addACHAccount: 'api/ach-account',
  verifyACHAccount: 'api/ach-account/verify',
  verifyCreditCard: 'api/ach-account/card/verify',
  deleteACHAccount: 'api/ach-account/delete',
  getACHAccount: 'api/ach-account',
  achAccountList: 'api/ach-account',
  achAccountListIndex: "api/ach-account-index",
  getApplicationFee: 'api/application/fee',
  applicationPayment: 'api/application-payment',
  searchAddress: 'api/city/address/',
  getCityList: 'api/cities',

  getCSRFToken: 'api/csrf-token',
  verifyCSRFToken: `api/verify-csrf-token`,

  downloadPermit: 'api/admin/download-certificate/',
  getAdminApplication: 'api/admin/applications',
  getCityAdminList: 'api/admin/city-admin',
  addCityAdmin: 'api/admin/city-admin',
  saveAddress: 'api/admin/upload/address',
  addStaffByCityAdmin: 'api/admin/staff',
  getCityAdminStaffList: 'api/admin/staff',
  updateCityAdmin: 'api/admin/city-admin',
  adminApplicationById: 'api/admin/application/',
  postSubmissionReview: 'api/admin/clerk-submission-review',
  voidSubmissionReview: 'api/admin/clerk-submission-review/void',
  applicationUpdateByAdmin: 'api/admin/application/update',
  postDecision: 'api/admin/decision',
  voidDecision: 'api/admin/decision/void',
  submitNotes: 'api/admin/notes',
  postEmail: 'api/admin/send-mail',
  deleteNote: 'api/admin/notes/delete',
  postPayment: 'api/admin/add/fee',
  waiveOffFee: 'api/admin/waive-off/fee',
  managerProfileUploadSignature: 'api/admin/city-admin/profile',
  cityAdminStaffResetPassword: 'api/admin/staff/password/create',
  renewPermit:'api/renew-permit',
  saveWithoutpayment:'api/submitte-application',
  verifyCSRFTokenAdmin: `api/admin/verify-csrf-token`,
  sendOtpProfile:'api/admin/send-profile-phone-otp',
  verifiyOtpProfileadmin:'api/admin/verify-profile-otp',
  emailResendVerificationadmin: "api/admin/resend-email-link",

}

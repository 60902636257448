import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit, OnDestroy {

  public activeRoute: any;

  private subscription: Subscription = new Subscription();

  constructor(private router: Router) {
    /** GET CURRENT ROUTE */

    this.subscription.add(this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
          this.activeRoute = event.url.split('/')[event.url.split('/').length - 1].split('?')[0];
      }
    }));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}

export const appToaster = {
  successHead: 'Success',
  errorHead: 'An Error Occurred',
  apiErrorHead: 'Error',
  loginSuccess: 'You have successfully logged in',
  logoutSuccess: 'You have successfully logged out',
  registerSuccess: 'You have successfully registered',
  profileUpdateSuccess: 'Your profile updated successfully',
  renewPermit:'Renew Permit Add successfully'
};
